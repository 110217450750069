exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/Blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-blog-discovering-the-northern-lights-in-iceland-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/blog/discovering-the-northern-lights-in-iceland.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-blog-discovering-the-northern-lights-in-iceland-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-blog-exploring-modern-marvels-architecture-across-the-globe-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/blog/exploring-modern-marvels-architecture-across-the-globe.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-blog-exploring-modern-marvels-architecture-across-the-globe-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-blog-gastronomic-adventure-in-tuscany-italy-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/blog/gastronomic-adventure-in-tuscany-Italy.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-blog-gastronomic-adventure-in-tuscany-italy-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-blog-mindful-retreat-finding-serenity-in-bali-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/blog/mindful-retreat-finding-serenity-in-Bali.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-blog-mindful-retreat-finding-serenity-in-bali-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-blog-tech-wonders-exploring-silicon-valley-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/blog/tech-wonders-exploring-silicon-valley.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-blog-tech-wonders-exploring-silicon-valley-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-blog-tour-por-las-capitales-de-la-moda-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/blog/tour-por-las-capitales-de-la-moda.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-jsx-content-file-path-blog-tour-por-las-capitales-de-la-moda-mdx" */),
  "component---src-pages-doc-js": () => import("./../../../src/pages/doc.js" /* webpackChunkName: "component---src-pages-doc-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

